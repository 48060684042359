import {
  Autocomplete,
  autocompleteClasses,
  Box,
  Button,
  Card,
  Chip,
  GlobalStyles,
  Menu,
  MenuItem,
  Popover,
  popoverClasses,
  Stack,
  SvgIcon,
  svgIconClasses,
  TextField,
  Typography
} from '@mui/material'
import React, { useRef, useState } from 'react'

import { Feed_Types } from '~ui-components/types/__generated/gql/graphql'

import { VirtualizedMapGrid as MapGrid } from '~ui-components/components/molecules/VirtualizedMapGrid'
import { AppLayout } from '~ui-components/components/organisms/AppLayout'

import ChevronDownIcon from '@untitled-ui/icons-react/build/esm/ChevronDown'
import LayerThreeIcon from '@untitled-ui/icons-react/build/esm/LayersThree01'
import Lightbulb02Icon from '@untitled-ui/icons-react/build/esm/Lightbulb02'
import MarkerPin06Icon from '@untitled-ui/icons-react/build/esm/MarkerPin06'
import PlusCircleIcon from '@untitled-ui/icons-react/build/esm/PlusCircle'
import Star01Icon from '@untitled-ui/icons-react/build/esm/Star01'

const Empty = () => false

const Overrides = (
  <GlobalStyles
    styles={{
      overscrollBehavior: 'none'
    }}
  />
)

const defaultSlots = {
  MapGrid,
  MapSearchSortInput,
  MapSearchTagsInput,
  MapSearchTagAddInput
}

const MapSearch = (props) => {
  const { slots = {}, slotProps = {} } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  const Main = (
    <>
      <Card sx={{ mt: 2, mx: 2 }}>
        <Stack
          sx={{ p: 2 }}
          direction='row'
          alignItems='center'
          spacing={2}
        >
          <Slots.MapSearchSortInput {...slotProps?.MapSearchSortInput} />
          <Slots.MapSearchTagsInput {...slotProps?.MapSearchTagsInput} />
          <Slots.MapSearchTagAddInput {...slotProps?.MapSearchTagAddInput} />
        </Stack>
      </Card>
      <Slots.MapGrid {...slotProps?.MapGrid} />
    </>
  )

  return (
    <>
      {Overrides}
      <AppLayout
        slots={{
          Header: Slots.Header,
          Main,
          Footer: Empty
        }}
      />
    </>
  )
}

export function MapSearchSortInput(props) {
  return (
    <ButtonSelect
      size='small'
      label='Sort'
      {...props}
    >
      <ButtonSelectOption
        label='All'
        value={Feed_Types.All}
        icon={
          <SvgIcon>
            <LayerThreeIcon />
          </SvgIcon>
        }
      />
      <ButtonSelectOption
        label='Near me'
        value={Feed_Types.Near}
        icon={
          <SvgIcon>
            <MarkerPin06Icon />
          </SvgIcon>
        }
      />
      <ButtonSelectOption
        label='New'
        value={Feed_Types.New}
        icon={
          <SvgIcon>
            <Lightbulb02Icon />
          </SvgIcon>
        }
      />
      <ButtonSelectOption
        label='Most popular'
        value={Feed_Types.Popular}
        icon={
          <SvgIcon>
            <Star01Icon />
          </SvgIcon>
        }
      />
    </ButtonSelect>
  )
}

MapSearchSortInput.defaultValue = Feed_Types.All

type Tag = {
  id: string
  name: string
  type: string
}

interface MapSearchTagsInputProps {
  options: Array<Tag>
  onDelete: (id: string) => void
}

export function MapSearchTagsInput(props: MapSearchTagsInputProps) {
  const { options = [], onDelete = () => {} } = props

  if (!options.length) return null

  return (
    <Stack
      alignItems='center'
      direction='row'
      flexWrap='wrap'
      gap={1}
    >
      {options.map((option) => (
        <Chip
          key={option.id}
          label={
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                textTransform: 'capitalize',
                '& span': {
                  mr: 0.25,
                  fontWeight: 600
                }
              }}
            >
              <span>{option.type}:</span> {option.name}
            </Box>
          }
          onDelete={() => onDelete(option.id)}
        />
      ))}
    </Stack>
  )
}

export function MapSearchTagAddInput(props) {
  const {
    label,
    title,
    displayValue,
    value,
    inputValue,
    options,
    onInputChange,
    onEnter = () => {},
    onApply = () => {},
    ...rest
  } = props

  const anchorRef = useRef(null)

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        size='small'
        ref={anchorRef}
        color='inherit'
        onClick={handleOpen}
        startIcon={
          <SvgIcon>
            <PlusCircleIcon />
          </SvgIcon>
        }
      >
        <span>
          {label || 'Tag'}
          {displayValue ? (
            <React.Fragment>
              :{' '}
              <Box
                component='span'
                sx={{ color: 'var(--mui-palette-primary-main)' }}
              >
                {displayValue}
              </Box>
            </React.Fragment>
          ) : null}
        </span>
      </Button>

      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        onClose={handleClose}
        open={open}
        sx={{ [`.${popoverClasses.paper}`]: { mt: '4px', width: '320px' } }}
      >
        <Stack
          spacing={2}
          sx={{ p: 2 }}
        >
          <Typography variant='subtitle2'>
            {title || 'Filter by tag'}
          </Typography>
          <Autocomplete
            inputValue={inputValue}
            options={options}
            renderOption={(props, option: Tag, state, ownerState) => {
              const { key, ...optionProps } = props as any
              return (
                <Stack
                  key={key}
                  component='li'
                  direction='row'
                  flexWrap='wrap'
                  sx={{
                    borderRadius: 1,
                    mx: 1,
                    [`&.${autocompleteClasses.option}`]: {}
                  }}
                  {...optionProps}
                >
                  <Typography
                    flex={1}
                    variant='inherit'
                  >
                    {ownerState.getOptionLabel(option)}
                  </Typography>
                  <Chip
                    label={option.type}
                    size='small'
                  />
                </Stack>
              )
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
              />
            )}
            sx={{
              [`.${autocompleteClasses.popper}`]: {}
            }}
            slotProps={{
              popper: {
                sx: {
                  zIndex: (theme) => theme.zIndex.tooltip + 9
                }
              }
            }}
            onInputChange={onInputChange}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                onEnter?.()
                handleClose()
              }
            }}
            {...rest}
          />
          <Button
            onClick={() => {
              onApply?.()
              handleClose()
            }}
            variant='contained'
          >
            Apply
          </Button>
        </Stack>
      </Popover>
    </>
  )
}

function ButtonSelect(props) {
  const { label, children, value, onChange, ...rest } = props

  const ref = useRef(null)
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button
        ref={ref}
        color='inherit'
        endIcon={
          <SvgIcon>
            <ChevronDownIcon />
          </SvgIcon>
        }
        onClick={handleOpen}
        {...rest}
      >
        {label}
      </Button>

      <Menu
        anchorEl={ref.current}
        open={open}
        onClose={handleClose}
        sx={{}}
        slotProps={{
          paper: {
            sx: { width: 250, marginTop: '4px' }
          }
        }}
      >
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            currentValue: value,
            handleClose,
            onChange
          })
        )}
      </Menu>
    </>
  )
}

function ButtonSelectOption(props) {
  const { label, icon, currentValue, value, onChange, handleClose, ...rest } =
    props

  const active = currentValue === value

  return (
    <MenuItem
      key={label}
      selected={active}
      sx={{
        mx: 1,
        borderRadius: 1,
        fontWeight: 500,
        color: active ? 'primary.main' : 'text.secondary',
        [`.${svgIconClasses.root}`]: {
          mr: 1,
          color: active ? 'primary.main' : 'text.secondary'
        }
      }}
      onClick={(event) => {
        onChange?.(event, value)
        handleClose?.()
      }}
      {...rest}
    >
      {icon}
      {label}
    </MenuItem>
  )
}

export { MapSearch }
export default MapSearch
