import {
  Avatar,
  Box,
  CardMedia,
  CardMediaProps,
  Divider,
  Link,
  Card as MuiCard,
  SvgIcon,
  Typography
} from '@mui/material'
import { Eye, Star01 } from '@untitled-ui/icons-react/build/esm'
import { dayjs } from 'utils'

import { ConfigType } from 'dayjs'
import { forwardRef } from 'react'

export interface CardProps {
  href: string
  cover: string
  title: string
  avatar?: {
    image: string
    name: string
    href: string
    updatedAt: ConfigType
  }
  footer?: { likes: number; views: number }
  cardMediaProps?: Omit<CardMediaProps, 'image'>
  rootCardProps?: CardMediaProps
}

export const Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
  const { title, href, cover, avatar, footer, cardMediaProps, ...rest } = props

  return (
    <MuiCard
      ref={ref}
      {...rest}
    >
      <Box sx={{ p: 2 }}>
        <CardMedia
          component={Link}
          href={href}
          image={cover}
          {...cardMediaProps}
          sx={{
            backgroundColor: 'neutral.50',
            height: 200,
            borderRadius: 1,
            ...cardMediaProps?.sx
          }}
        />
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            mt: 2
          }}
        >
          {avatar && <Avatar src={avatar.image} />}
          <Box>
            <Link
              href={href}
              color='text.primary'
              variant='h6'
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                textOverflow: 'ellipsis'
              }}
            >
              {title}
            </Link>
            {avatar && (
              <Typography
                color='text.secondary'
                variant='body2'
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  textOverflow: 'ellipsis'
                }}
              >
                by&nbsp;
                <Link
                  href={avatar.href}
                  color='text.primary'
                  variant='subtitle2'
                >
                  {avatar.name}
                </Link>
                &nbsp; | Updated {dayjs().to(dayjs(avatar.updatedAt))}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      {!!footer && (
        <>
          <Divider />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              pl: 2,
              pr: 3,
              py: 2
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <SvgIcon>
                <Star01 />
              </SvgIcon>
              <Typography
                color='text.secondary'
                variant='subtitle2'
                sx={{ ml: 1 }}
              >
                {footer.likes}
              </Typography>
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                ml: 2
              }}
            >
              <SvgIcon>
                <Eye />
              </SvgIcon>
              <Typography
                color='text.secondary'
                sx={{ ml: 1 }}
                variant='subtitle2'
              >
                {footer.views}
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
          </Box>
        </>
      )}
    </MuiCard>
  )
})
