import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { Fragment, useState } from 'react'

import { MapViewerProvider } from 'map-viewer'
import { useLoadingIndicator } from 'promise-tracker'
import { GoogleTagManager } from 'tag-manager'
import { AppErrorDialog, ErrorBoundary, UIProvider } from 'ui-components'
import { AuthProvider } from 'user-auth'
import { ReactDevTools } from 'utils'

import { Amplify } from 'aws-amplify'
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito'
import { CookieStorage } from 'aws-amplify/utils'
import { configure } from 'web-amplify/configure'

import 'overlayscrollbars/overlayscrollbars.css'
import 'promise-tracker/src/assets/styles/nprogress.css'
import 'simplebar-react/dist/simplebar.min.css'
import './index.css'

import 'aws-amplify/auth/enable-oauth-listener'

Amplify.configure(configure, {
  ssr: true
})

cognitoUserPoolsTokenProvider.setKeyValueStorage(
  new CookieStorage({
    secure: false
  })
)

/**
 * Enable or disable React Dev Tools
 * based on NODE_ENV or ENABLE_REACT_DEV_TOOLS
 */
ReactDevTools.init()

interface CustomAppProps extends AppProps {
  Component: NextPage
}

const App = ({ Component, pageProps }: CustomAppProps) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // With SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            staleTime: 10 * 1000
          }
        }
      })
  )

  useLoadingIndicator()

  return (
    <Fragment>
      <Head>
        <meta
          name='viewport'
          content='initial-scale=1, viewport-fit=cover, width=device-width, user-scalable=no'
        />
        <meta
          name='apple-mobile-web-app-capable'
          content='yes'
        />
        <meta
          name='apple-mobile-web-app-status-bar-style'
          content='black-translucent'
        />
      </Head>

      <GoogleTagManager
        gtmId={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID as string}
        gaId={process.env.GA_MEASUREMENT_ID as string}
      />

      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={pageProps.dehydratedState}>
          <AuthProvider>
            <UIProvider>
              <ErrorBoundary fallback={Fallback}>
                <MapViewerProvider>
                  <Component {...pageProps} />
                </MapViewerProvider>
              </ErrorBoundary>
            </UIProvider>
          </AuthProvider>
        </HydrationBoundary>
      </QueryClientProvider>
    </Fragment>
  )
}

function Fallback() {
  return <AppErrorDialog open />
}

export default App
