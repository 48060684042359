/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AWSDate: { input: string; output: string; }
  AWSDateTime: { input: string; output: string; }
  AWSEmail: { input: string; output: string; }
  AWSIPAddres: { input: string; output: string; }
  AWSJSON: { input: string; output: string; }
  AWSPhone: { input: string; output: string; }
  AWSTime: { input: string; output: string; }
  AWSTimestamp: { input: number; output: number; }
  AWSURL: { input: string; output: string; }
};

export enum Category_Tag_Type {
  Featured = 'featured',
  Primary = 'primary',
  Secondary = 'secondary'
}

export enum Create_Tag_Types {
  CoverageArea = 'coverageArea',
  FeatureType = 'featureType',
  Topic = 'topic'
}

export type CsvFileUploadResult = {
  dataId: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  formFieldsJson: Scalars['AWSJSON']['output'];
  uploadUrl: Scalars['AWSURL']['output'];
};

export type CategoryTagInput = {
  categoryType: Category_Tag_Type;
  name: Scalars['String']['input'];
  thumbnail: Scalars['String']['input'];
};

export type Coordinates = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};

export type CreateMapInput = {
  bbox?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  centroid?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  dataId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  license?: InputMaybe<MapLicenseInput>;
  likes?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  properties: Array<MapPropertyInput>;
  remixes?: InputMaybe<Scalars['Int']['input']>;
  source?: InputMaybe<MapSourceInput>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  type: Map_Type;
  views?: InputMaybe<Scalars['Int']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type CreateTagInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  type: Create_Tag_Types;
};

export type CreateWorkspaceInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWorkspaceMemberInput = {
  inviteId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type CsvAugmentationInput = {
  boundaryDataFileName?: InputMaybe<Scalars['String']['input']>;
  dataId: Scalars['String']['input'];
};

export type CsvAugmentationResult = {
  dataId: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  fileUrl: Scalars['String']['output'];
  missRows?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  titleColumnName: Scalars['String']['output'];
};

export type DataIdentifier = {
  bbox: Array<Scalars['Float']['output']>;
  dataId: Scalars['ID']['output'];
  featureId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export enum Feed_Types {
  All = 'all',
  Near = 'near',
  New = 'new',
  Popular = 'popular'
}

export type FileUploadUrlResult = {
  fileUrl: Scalars['AWSURL']['output'];
  formFieldsJson: Scalars['AWSJSON']['output'];
  uploadUrl: Scalars['AWSURL']['output'];
};

export type GeocodeComplete = {
  failureCount?: Maybe<Scalars['Int']['output']>;
  recordCount?: Maybe<Scalars['Int']['output']>;
  resultsFilePath?: Maybe<Scalars['String']['output']>;
  subscriptionId: Scalars['ID']['output'];
};

export type GeocodeCompleteInput = {
  failureCount?: InputMaybe<Scalars['Int']['input']>;
  recordCount?: InputMaybe<Scalars['Int']['input']>;
  resultsFilePath?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
};

export type GeocodeFailure = {
  devMessage?: Maybe<Scalars['String']['output']>;
  errorType?: Maybe<Scalars['String']['output']>;
  subscriptionId: Scalars['ID']['output'];
  userMessage?: Maybe<Scalars['String']['output']>;
};

export type GeocodeFailureInput = {
  devMessage?: InputMaybe<Scalars['String']['input']>;
  errorType?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
  userMessage?: InputMaybe<Scalars['String']['input']>;
};

export type GeocodeJobStatus = {
  recordCount?: Maybe<Scalars['Int']['output']>;
  subscriptionId: Scalars['ID']['output'];
};

export type GeocodeJobStatusInput = {
  recordCount?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId: Scalars['ID']['input'];
};

export type GeocodeResult = {
  address?: Maybe<Scalars['String']['output']>;
  isOutlier?: Maybe<Scalars['Boolean']['output']>;
  msuuid?: Maybe<Scalars['String']['output']>;
  point?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  subscriptionId?: Maybe<Scalars['ID']['output']>;
};

export type GeocodeResultInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  isOutlier?: InputMaybe<Scalars['Boolean']['input']>;
  msuuid?: InputMaybe<Scalars['String']['input']>;
  point?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  subscriptionId?: InputMaybe<Scalars['ID']['input']>;
};

export type GeocodeResults = {
  data?: Maybe<Array<Maybe<GeocodeResult>>>;
  failureCount?: Maybe<Scalars['Int']['output']>;
  recordCount?: Maybe<Scalars['Int']['output']>;
  subscriptionId?: Maybe<Scalars['ID']['output']>;
};

export type GeocodeResultsInput = {
  data?: InputMaybe<Array<InputMaybe<GeocodeResultInput>>>;
  failureCount?: InputMaybe<Scalars['Int']['input']>;
  recordCount?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId?: InputMaybe<Scalars['ID']['input']>;
};

export enum Invited_User_Role {
  Guest = 'guest',
  Member = 'member'
}

export type InvitedUser = {
  createdAt: Scalars['AWSDateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mapGuestAccessCollection: MapGuestAccessCollection;
  role: Invited_User_Role;
  updatedAt: Scalars['AWSDateTime']['output'];
  userId: Scalars['ID']['output'];
  workspace: Workspace;
  workspaceId: Scalars['ID']['output'];
};

export type InvitedUserCollection = {
  items: Array<InvitedUser>;
  total: Scalars['Int']['output'];
};

export enum Json_Extensions {
  Geojson = 'geojson',
  Json = 'json'
}

export enum Map_Property_Types {
  Boolean = 'boolean',
  Date = 'date',
  Float = 'float',
  Integer = 'integer',
  Number = 'number',
  Object = 'object',
  Text = 'text'
}

export enum Map_Property_Visualisation_Types {
  Category = 'category',
  None = 'none',
  Quantity = 'quantity'
}

export enum Map_Type {
  Points = 'points',
  Polygons = 'polygons'
}

export enum Member_Role {
  Guest = 'guest',
  Member = 'member',
  Owner = 'owner'
}

export type Map = {
  bbox?: Maybe<Array<Scalars['Float']['output']>>;
  centroid: Array<Scalars['Float']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  creator: User;
  creatorId: Scalars['ID']['output'];
  dataId: Scalars['ID']['output'];
  dataReady?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  embedKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPublic: Scalars['Boolean']['output'];
  license?: Maybe<MapLicense>;
  liked: Scalars['Boolean']['output'];
  likes: Scalars['Int']['output'];
  mapGuestAccessCollection: MapGuestAccessCollection;
  mapRemixCollection: MapRemixCollection;
  mapTagCollection: MapTagCollection;
  name: Scalars['String']['output'];
  owned: Scalars['Boolean']['output'];
  parentMap?: Maybe<Map>;
  parentMapId?: Maybe<Scalars['ID']['output']>;
  properties: Array<MapProperty>;
  readme?: Maybe<Readme>;
  remixes: Scalars['Int']['output'];
  source?: Maybe<MapSource>;
  type: Map_Type;
  updatedAt: Scalars['AWSDateTime']['output'];
  views: Scalars['Int']['output'];
  workspace: Workspace;
  workspaceId: Scalars['ID']['output'];
};


export type MapMapRemixCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type MapCollection = {
  items: Array<Map>;
  total: Scalars['Int']['output'];
};

export type MapData = {
  dataId: Scalars['ID']['output'];
};

export type MapDataInput = {
  dataId: Scalars['ID']['input'];
};

export type MapFilterInput = {
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MapGuestAccess = {
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  invitedUser?: Maybe<InvitedUser>;
  map: Map;
  mapId: Scalars['ID']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
  user?: Maybe<User>;
  userId: Scalars['ID']['output'];
  workspace: Workspace;
  workspaceId: Scalars['ID']['output'];
};

export type MapGuestAccessCollection = {
  items: Array<MapGuestAccess>;
  total: Scalars['Int']['output'];
};

export type MapLicense = {
  name: Scalars['String']['output'];
  url: Scalars['AWSURL']['output'];
};

export type MapLicenseInput = {
  name: Scalars['String']['input'];
  url: Scalars['AWSURL']['input'];
};

export type MapProperty = {
  classBreaks?: Maybe<Array<Scalars['String']['output']>>;
  isAggregate: Scalars['Boolean']['output'];
  isDefaultVisualisation: Scalars['Boolean']['output'];
  isPercentage: Scalars['Boolean']['output'];
  isTitle: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  type?: Maybe<Map_Property_Types>;
  visualisation: Map_Property_Visualisation_Types;
};

export type MapPropertyInput = {
  classBreaks?: InputMaybe<Array<Scalars['String']['input']>>;
  isAggregate: Scalars['Boolean']['input'];
  isDefaultVisualisation: Scalars['Boolean']['input'];
  isPercentage: Scalars['Boolean']['input'];
  isTitle: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  type: Map_Property_Types;
  visualisation: Map_Property_Visualisation_Types;
};

export type MapRemix = {
  childMap: Map;
  creator: User;
  id: Scalars['ID']['output'];
  parentMap: Map;
  workspace: Workspace;
};

export type MapRemixCollection = {
  items: Array<MapRemix>;
  total: Scalars['Int']['output'];
};

export type MapSearch = {
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  createdAt: Scalars['AWSDateTime']['output'];
  creator: User;
  dataId: Scalars['ID']['output'];
  dataReady?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  liked?: Maybe<Scalars['Boolean']['output']>;
  likes?: Maybe<Scalars['Int']['output']>;
  mapTagCollection: MapTagCollection;
  name: Scalars['String']['output'];
  properties?: Maybe<Array<Maybe<MapProperty>>>;
  remixes?: Maybe<Scalars['Int']['output']>;
  type: Map_Type;
  updatedAt: Scalars['AWSDateTime']['output'];
  views?: Maybe<Scalars['Int']['output']>;
  workspace: Workspace;
};

export type MapSearchCollection = {
  items: Array<MapSearch>;
  total: Scalars['Int']['output'];
};

export type MapSource = {
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['AWSURL']['output']>;
};

export type MapSourceInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['AWSURL']['input']>;
};

export type MapTag = {
  id: Scalars['ID']['output'];
  map?: Maybe<Map>;
  tag?: Maybe<Tag>;
};

export type MapTagCollection = {
  items: Array<Maybe<MapTag>>;
  total: Scalars['Int']['output'];
};

export type MapTagInput = {
  mapId: Scalars['ID']['input'];
  tagId: Scalars['ID']['input'];
};

export type MapThumbnailUploadUrlResult = {
  formFieldsJson: Scalars['AWSJSON']['output'];
  uploadUrl: Scalars['AWSURL']['output'];
};

export type Member = {
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  mapGuestAccessCollection: MapGuestAccessCollection;
  role: Member_Role;
  user: User;
  userId: Scalars['ID']['output'];
  workspace: Workspace;
  workspaceId: Scalars['ID']['output'];
};

export type MemberCollection = {
  items: Array<Member>;
  total: Scalars['Int']['output'];
};

export type MembershipCollection = {
  items: Array<Member>;
  total: Scalars['Int']['output'];
};

export type Mutation = {
  addMapView: Map;
  createAugmentedCSV?: Maybe<CsvAugmentationResult>;
  createCategoryTag: Tag;
  createMap: Map;
  createMapGuestAccess: Scalars['Boolean']['output'];
  createMapTag: MapTag;
  createMemberInvitedUser: Scalars['Boolean']['output'];
  createTag: Tag;
  createWorkspace: Workspace;
  createWorkspaceMember: Member;
  deleteCategoryTagById: Tag;
  deleteInvitedUser: Scalars['Boolean']['output'];
  deleteMapById?: Maybe<Map>;
  deleteOembedMap: Scalars['Boolean']['output'];
  deleteWorkspaceById: Workspace;
  deleteWorkspaceMember: Member;
  embedMap: Scalars['String']['output'];
  generateMapThumbnail: Scalars['Boolean']['output'];
  geocodeAddressFile?: Maybe<GeocodeJobStatus>;
  likeMap?: Maybe<Scalars['Boolean']['output']>;
  presignAddressFileUpload: PresignAddressFileUploadResults;
  remixMap?: Maybe<Scalars['Boolean']['output']>;
  removeMapGuestAccess: Scalars['Boolean']['output'];
  saveReadme: Readme;
  sendEmail: Scalars['Boolean']['output'];
  triggerAddressGeocodeComplete?: Maybe<GeocodeComplete>;
  triggerAddressGeocodeFailure?: Maybe<GeocodeFailure>;
  triggerAddressGeocodeResultBatch?: Maybe<GeocodeResults>;
  triggerMapDataFail?: Maybe<MapData>;
  triggerMapDataReady?: Maybe<MapData>;
  unlikeMap?: Maybe<Scalars['Boolean']['output']>;
  updateCategoryTagById: Tag;
  updateMap: Map;
  updateMapAccess: Map;
  updateWorkspaceById: Workspace;
  upgradeToMember: Scalars['Boolean']['output'];
};


export type MutationAddMapViewArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateAugmentedCsvArgs = {
  input: CsvAugmentationInput;
};


export type MutationCreateCategoryTagArgs = {
  mapIds: Array<Scalars['ID']['input']>;
  tagInput: CategoryTagInput;
};


export type MutationCreateMapArgs = {
  input: CreateMapInput;
};


export type MutationCreateMapGuestAccessArgs = {
  email: Scalars['String']['input'];
  mapId: Scalars['ID']['input'];
};


export type MutationCreateMapTagArgs = {
  input: MapTagInput;
};


export type MutationCreateMemberInvitedUserArgs = {
  email: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};


export type MutationCreateTagArgs = {
  input: CreateTagInput;
};


export type MutationCreateWorkspaceArgs = {
  input: CreateWorkspaceInput;
};


export type MutationCreateWorkspaceMemberArgs = {
  input: CreateWorkspaceMemberInput;
};


export type MutationDeleteCategoryTagByIdArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteInvitedUserArgs = {
  userId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};


export type MutationDeleteMapByIdArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteOembedMapArgs = {
  mapId: Scalars['ID']['input'];
};


export type MutationDeleteWorkspaceByIdArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteWorkspaceMemberArgs = {
  id: Scalars['ID']['input'];
};


export type MutationEmbedMapArgs = {
  mapId: Scalars['ID']['input'];
};


export type MutationGenerateMapThumbnailArgs = {
  mapId: Scalars['ID']['input'];
  options?: InputMaybe<Scalars['AWSJSON']['input']>;
};


export type MutationGeocodeAddressFileArgs = {
  filename: Scalars['String']['input'];
};


export type MutationLikeMapArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPresignAddressFileUploadArgs = {
  extension: Supported_Address_File_Formats;
  fileName: Scalars['String']['input'];
};


export type MutationRemixMapArgs = {
  childMapId: Scalars['ID']['input'];
  parentMapId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};


export type MutationRemoveMapGuestAccessArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSaveReadmeArgs = {
  mapId: Scalars['ID']['input'];
  nodes: Array<Scalars['AWSJSON']['input']>;
};


export type MutationSendEmailArgs = {
  input: SendEmailInput;
};


export type MutationTriggerAddressGeocodeCompleteArgs = {
  input?: InputMaybe<GeocodeCompleteInput>;
};


export type MutationTriggerAddressGeocodeFailureArgs = {
  input?: InputMaybe<GeocodeFailureInput>;
};


export type MutationTriggerAddressGeocodeResultBatchArgs = {
  input?: InputMaybe<GeocodeResultsInput>;
};


export type MutationTriggerMapDataFailArgs = {
  input: MapDataInput;
};


export type MutationTriggerMapDataReadyArgs = {
  input: MapDataInput;
};


export type MutationUnlikeMapArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateCategoryTagByIdArgs = {
  id: Scalars['ID']['input'];
  mapIds: Array<Scalars['ID']['input']>;
  tagInput: CategoryTagInput;
};


export type MutationUpdateMapArgs = {
  input: UpdateMapInput;
};


export type MutationUpdateMapAccessArgs = {
  id: Scalars['ID']['input'];
  isPublic: Scalars['Boolean']['input'];
};


export type MutationUpdateWorkspaceByIdArgs = {
  id: Scalars['ID']['input'];
  input: UpdateWorkspaceInput;
};


export type MutationUpgradeToMemberArgs = {
  userId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type PresignAddressFileUploadResults = {
  formFieldsJson: Scalars['AWSJSON']['output'];
  path: Scalars['String']['output'];
  uploadUrl: Scalars['AWSURL']['output'];
};

export type Query = {
  adminGetMapById: Map;
  countCategoryTags: Scalars['Int']['output'];
  dataExists: Scalars['Boolean']['output'];
  getCSVFileUploadUrl: CsvFileUploadResult;
  getCategoryTagById: Tag;
  getCategoryTags: Array<Tag>;
  getFeaturedMaps: MapCollection;
  getFileUploadUrl: FileUploadUrlResult;
  getMapById: Map;
  getMapThumbnailUploadUrl: MapThumbnailUploadUrlResult;
  getMaps: Array<Map>;
  getReadmeAssetsUploadUrl: ReadmeUploadUrlResult;
  getTagById?: Maybe<Tag>;
  getTagThumbnailUploadUrl: TagThumbnailUploadUrl;
  getTagsByIds: Array<Tag>;
  getUserByEmail?: Maybe<User>;
  getUserById: User;
  getWorkspaceById?: Maybe<Workspace>;
  getWorkspaceLogoUploadUrl: WorkspaceLogoUploadUrl;
  getWorkspaces: WorkspaceCollection;
  searchMaps: MapCollection;
  searchTags: TagCollection;
};


export type QueryAdminGetMapByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDataExistsArgs = {
  dataId: Scalars['ID']['input'];
};


export type QueryGetCategoryTagByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCategoryTagsArgs = {
  from: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
};


export type QueryGetFeaturedMapsArgs = {
  from?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetFileUploadUrlArgs = {
  extension: Json_Extensions;
  id: Scalars['ID']['input'];
};


export type QueryGetMapByIdArgs = {
  embedKey?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};


export type QueryGetMapThumbnailUploadUrlArgs = {
  mapId: Scalars['ID']['input'];
};


export type QueryGetMapsArgs = {
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGetReadmeAssetsUploadUrlArgs = {
  contentType: Scalars['String']['input'];
  mapId: Scalars['ID']['input'];
};


export type QueryGetTagByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetTagThumbnailUploadUrlArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetTagsByIdsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryGetUserByEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetUserByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetWorkspaceByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetWorkspaceLogoUploadUrlArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetWorkspacesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  mapFilter?: InputMaybe<MapFilterInput>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySearchMapsArgs = {
  coords?: InputMaybe<Coordinates>;
  creatorId?: InputMaybe<Scalars['ID']['input']>;
  feedType?: InputMaybe<Feed_Types>;
  from?: InputMaybe<Scalars['Int']['input']>;
  includePrivate?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<SearchMapsSortByInput>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerySearchTagsArgs = {
  categoryTypes?: InputMaybe<Array<Category_Tag_Type>>;
  from?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Search_Tags_Sort>;
  types?: InputMaybe<Array<Tag_Types>>;
};

export type Readme = {
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  nodes: Array<Scalars['AWSJSON']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type ReadmeUploadUrlResult = {
  path: Scalars['String']['output'];
  presignedPost: Scalars['AWSJSON']['output'];
};

export enum Search_Tags_Sort {
  Name = 'name',
  New = 'new',
  Popular = 'popular'
}

export enum Sort_Order {
  Asc = 'asc',
  Desc = 'desc'
}

export enum Supported_Address_File_Formats {
  Csv = 'csv',
  Geojson = 'geojson',
  Xls = 'xls',
  Xlsx = 'xlsx'
}

export type SearchMapsSortByInput = {
  field: Scalars['String']['input'];
  order: Sort_Order;
};

export type SendEmailInput = {
  content: Scalars['String']['input'];
  from?: InputMaybe<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
  to: Array<InputMaybe<Scalars['String']['input']>>;
};

export type Subscription = {
  onAddressGeocodeComplete?: Maybe<GeocodeComplete>;
  onAddressGeocodeFailure?: Maybe<GeocodeFailure>;
  onAddressGeocodeResultBatch?: Maybe<GeocodeResults>;
  onMapDataFail?: Maybe<MapData>;
  onMapDataReady?: Maybe<MapData>;
};


export type SubscriptionOnAddressGeocodeCompleteArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type SubscriptionOnAddressGeocodeFailureArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type SubscriptionOnAddressGeocodeResultBatchArgs = {
  subscriptionId: Scalars['String']['input'];
};


export type SubscriptionOnMapDataFailArgs = {
  dataId: Scalars['ID']['input'];
};


export type SubscriptionOnMapDataReadyArgs = {
  dataId: Scalars['ID']['input'];
};

export enum Tag_Types {
  Category = 'category',
  CoverageArea = 'coverageArea',
  FeatureType = 'featureType',
  Topic = 'topic'
}

export type Tag = {
  categoryType?: Maybe<Category_Tag_Type>;
  count: Scalars['Int']['output'];
  createdAt: Scalars['AWSDateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mapTagCollection: MapTagCollection;
  name: Scalars['String']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
  type: Tag_Types;
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type TagCollection = {
  items: Array<Maybe<Tag>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type TagThumbnailUploadUrl = {
  formFieldsJson: Scalars['AWSJSON']['output'];
  path: Scalars['String']['output'];
  uploadUrl: Scalars['AWSURL']['output'];
};

export type UpdateMapInput = {
  dataId: Scalars['ID']['input'];
  deletedTagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  license?: InputMaybe<MapSourceInput>;
  name: Scalars['String']['input'];
  properties?: InputMaybe<Array<MapPropertyInput>>;
  source?: InputMaybe<MapSourceInput>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  workspaceId: Scalars['ID']['input'];
};

export type UpdateWorkspaceInput = {
  communityUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  twitterUsername?: InputMaybe<Scalars['String']['input']>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  avatar?: Maybe<Scalars['AWSURL']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  defaultWorkspace: Workspace;
  defaultWorkspaceId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mapCollection: MapCollection;
  membershipCollection: MembershipCollection;
  tagCollection: UserTagCollection;
  uid?: Maybe<Scalars['String']['output']>;
};


export type UserMembershipCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<Array<InputMaybe<Member_Role>>>;
};

export type UserTagCollection = {
  items: Array<Tag>;
  total: Scalars['Int']['output'];
};

export type Workspace = {
  communityUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  defaultUser?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  guestCollection: MembershipCollection;
  id: Scalars['ID']['output'];
  inviteId: Scalars['ID']['output'];
  invitedUserCollection: InvitedUserCollection;
  logo: Scalars['String']['output'];
  mapCollection: MapCollection;
  memberCollection: MembershipCollection;
  name: Scalars['String']['output'];
  tagCollection: WorkspaceTagCollection;
  twitterUsername?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type WorkspaceCollection = {
  items: Array<Workspace>;
  total: Scalars['Int']['output'];
};

export type WorkspaceLogoUploadUrl = {
  formFieldsJson: Scalars['AWSJSON']['output'];
  path: Scalars['String']['output'];
  uploadUrl: Scalars['AWSURL']['output'];
};

export type WorkspaceTagCollection = {
  items: Array<Tag>;
  total: Scalars['Int']['output'];
};
