import { Masonry } from '@mui/lab'
import { useMediaQuery } from '@mui/system'
import { useInView } from 'react-intersection-observer'
import { getMapLink, getMapThumbnailLink, getWorkspaceLink } from 'utils'

import { Card } from '../Card'

import { CardSkeleton } from '../Card/Skeleton'
import { EmptyMapResultFallback } from '../EmptyMapResultFallback'

const virtualizedMapGridConfig = {
  itemsPerRow: 4
}

function VirtualizedMapGrid(props) {
  const { loading, items = [], hasMoreItems, onEndReached } = props

  const xs = useMediaQuery<any>((theme) => theme.breakpoints.only('xs'))
  const md = useMediaQuery<any>((theme) => theme.breakpoints.only('md'))
  const lg = useMediaQuery<any>((theme) => theme.breakpoints.only('lg'))
  const xxl = useMediaQuery<any>((theme) => theme.breakpoints.only('xxl'))

  const skeleton = xs ? 1 : md ? 2 : lg ? 3 : xxl ? 4 : 4

  const [ref, inView] = useInView({
    onChange(inView, entry) {
      inView && hasMoreItems && onEndReached?.()
    }
  })

  if (loading && !items.length)
    return (
      <Masonry
        spacing={4}
        columns={{ xs: 1, md: 2, lg: 3, xxl: 4 }}
        sx={{ margin: 'unset', height: 'calc(1281px + 32px)' }}
      >
        {Array.from({ length: virtualizedMapGridConfig.itemsPerRow * 3 })
          .fill(null)
          .map((_, i) => (
            <CardSkeleton key={`skeleton-${i}`} />
          ))}
      </Masonry>
    )

  if (!loading && !items.length)
    return <EmptyMapResultFallback sx={[{ mx: 3 }]} />

  return (
    <Masonry
      spacing={4}
      columns={{ xs: 1, md: 2, lg: 3, xxl: 4 }}
      sx={{ m: 0, width: 'auto' }}
    >
      {items.map((item, index) => {
        return (
          <Card
            key={item.id}
            title={item.name}
            href={getMapLink({
              mapId: item.id,
              mapName: item.name
            })}
            cover={getMapThumbnailLink(item)}
            avatar={{
              image: item.workspace.logo ?? '',
              name: item.workspace.name ?? '',
              href: getWorkspaceLink({
                workspaceId: item.workspace.id,
                workspaceName: item.workspace.name
              }),
              updatedAt: item.updatedAt
            }}
            footer={{
              likes: item.likes ?? 0,
              views: item.views ?? 0
            }}
            {...(index === items.length - 1 ? { ref } : {})}
          />
        )
      })}
      {hasMoreItems &&
        Array.from({ length: skeleton }).map((_, index) => (
          <CardSkeleton key={index} />
        ))}
    </Masonry>
  )
}

export { VirtualizedMapGrid, virtualizedMapGridConfig }
export default VirtualizedMapGrid
